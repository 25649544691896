<template>
    <div>
        <div class="flex flex-col jce w-full py-20 sm:px-10 home-footer">
            <div class="flex flex-col lg:flex-row aie jcb footer-in min-container mx-auto">   
                <div class="flex flex-col ais jcb w-full">
                    <div class="flex w-full aie mb-16 lg:space-x-16">
                        <ul class="list-unstyled space-y-3 text-left hidden md:block">
                            <li><router-link to="/" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('Home')}}</router-link></li>
                            <li><router-link to="/about" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('About us')}}</router-link></li>
                            <li><router-link to="/services" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('Services')}}</router-link></li>
                            <li><router-link to="/blogs" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('Blogs')}}</router-link></li>
                            <li><button @click="showContact = true" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('Contact')}}</button></li>
                        </ul>
                        <div class="flex aie jcb w-full space-x-6">
                            <ul class="list-unstyled space-y-1 md:ml-10 lg:ml-0">
                                <li><router-link to="#" class="text-gray-900 lg:text-gray-500 hover:text-yellow-900 lg:hover:text-gray-900 text-sm md:text-base">info@ak.software</router-link></li>
                                <li><router-link to="#" class="text-gray-900 lg:text-gray-500 hover:text-yellow-900 lg:hover:text-gray-900 text-sm md:text-base">+49 176 76965445</router-link></li>
                                <li><router-link to="#" class="text-gray-900 lg:text-gray-500 hover:text-yellow-900 lg:hover:text-gray-900 text-sm md:text-base">Seydelstraße 7, 10117 berlin</router-link></li>
                            </ul>
                            <div class="flex lg:hidden flex-wrap sm:flex-nowrap aic jcb w-28 sm:w-auto sm:space-x-2">
                                <a href="https://www.facebook.com/Broetchenshop-106207038647892"
                                target="_blank"
                                rel="noopener"
                                class="social-items a-brown-light text-3xl opacity-90 hover:opacity-100"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/>
                                </svg>
                                </a>
                                <a href="https://www.instagram.com/accounts/login/?next=/broetchen.shop/"                          
                                target="_blank"
                                rel="noopener"
                                class="social-items a-brown-light text-3xl opacity-90 hover:opacity-100"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                                </svg>
                                </a>
                                <a href="https://twitter.com/orderiom"
                                target="_blank"
                                rel="noopener"
                                class="social-items a-brown-light text-3xl opacity-90 hover:opacity-100"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                                </svg>
                                </a>
                                <a href="https://www.linkedin.com/in/broetchen-shop-080881230/"
                                class="social-items a-brown-light text-3xl opacity-90 hover:opacity-100"
                                target="_blank"
                                rel="noopener"
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
                                </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col aie jcc top-text space-y-3 mb-8 lg:mb-12 mx-auto lg:mx-0">
                        <h2 class="text-2xl merienda-bold text-brown-light">BRÖTCHEN SHOP</h2>
                        <h3 class="text-2xl italianno-regular text-gray-500 subtitle">Dein leckerer Morgen</h3>
                    </div>
                    <div class="flex flex-col space-y-8 w-full">
                        <span class="w-full lg:w-10/12 text-gray-500 merienda-regular lg:max-w-xl text-sm text-center lg:text-left">
                            Sie können in Ruhe ausschlafen und ganz ohne Stress und ein leckeres Frühstück genießen. Wir liefern Ihnen morgens frische Brötchen.
                            Mit unserem Service können Sie Ihre eigene Bäckerei in Ihrem Zuhause haben.
                        </span>
                        <div class="hidden lg:flex aie jcb space-x-6">
                            <div class="flex w-1/3 border-b-4 border-gray-200"></div>     
                            <div class="flex flex-col lg:flex-row text-gray-500 fs-12 mt-5 xl:mt-0 whitespace-nowrap w-full">
                                <span>© 2021 Orderiom. Alle Rechte vorbehalten</span>
                                <span class="mx-1">|</span>
                                <button class="bg-transparent aic jcc fs-08 py-0 merienda-bold a-brown-dark" @click="showImpressum = true">Imprint</button>
                            </div>         
                        </div>
                    </div>
                </div>
                <div class="flex flex-col mb-auto px-4 lg:px-0 w-full lg:w-max lg:ml-auto">
                    <div class="flex flex-col w-full lg:w-max lg:border-b mb-8 lg:pb-4">
                        <h2 class="nunitoSans-bold text-xl mr-3 b text-center lg:text-left mt-12 md:mt-16"> Zahlungsmethode </h2>
                        <p class="hidden lg:block fs-08 text-gray-600"> Sie können uns in den folgenden Methoden bezahlen</p>
                    </div>
                    <div class="flex bot-social space-x-2 md:space-x-8">
                        <img src="/img/visa.webp" alt="Visa" width="300" height="92" class="h-6 md:h-7 lg:h-6 w-auto">
                        <img src="/img/mastercard.webp" alt="Visa" width="154" height="92" class="h-6 md:h-7 lg:h-6 w-auto">
                        <img src="/img/paypal.webp" alt="Paypal" width="352" height="92" class="h-6 md:h-7 lg:h-6 w-auto">
                    </div>
                    <div class="flex flex-col lg:hidden items-center lg:items-start xl:flex-row text-gray-500 fs-12 mt-12 pb-8 md:pb-0 whitespace-nowrap">
                        <div class="flex flex-col xl:flex-row text-gray-500 fs-12 mt-5 xl:mt-0 whitespace-nowrap w-full aic jcc">
                            <span>© 2021 Brötchen Shop. Alle Rechte vorbehalten</span>
                            <button class="bg-transparent aic jcc fs-08 py-0 merienda-bold mx-1 a-brown-dark" @click="showImpressum = true">Imprint</button>
                        </div>         
                    </div>
                </div>

            </div>
        </div>
        <div class="fixed bottom-0 left-0 w-full z-50 md:hidden">
            <div class="flex aic jcb w-full space-x-5 px-7" id="phone-nav">
                <router-link to="/" class="text-secondary opacity-90 w-1/5 h-10 flex aic jcc">
                    <span class="sr-only">Home</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M20 7.093v-5.093h-3v2.093l3 3zm4 5.907l-12-12-12 12h3v10h7v-5h4v5h7v-10h3zm-5 8h-3v-5h-8v5h-3v-10.26l7-6.912 7 6.99v10.182z"/>
                    </svg>                
                </router-link>
                <button @click="mobileMenuModal = true" class="text-secondary opacity-90 w-1/5 h-10 flex aic jcc">
                    <span class="sr-only">Contact</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z" fill="#1040e2"/>
                        <path d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z"/>
                    </svg>                
                </button>
                <!-- <router-link to="/services" class="text-secondary opacity-90 w-1/5 h-10 flex aic jcc">
                    <span class="sr-only">Dienstleistungen</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M24 23h-2.784l-1.07-3h-4.875l-1.077 3h-2.697l4.941-13h2.604l4.958 13zm-4.573-5.069l-1.705-4.903-1.712 4.903h3.417zm-9.252-10.804c.126-.486.201-.852.271-1.212l-2.199-.428c-.036.185-.102.533-.22 1-.742-.109-1.532-.122-2.332-.041.019-.537.052-1.063.098-1.569h2.456v-2.083h-2.161c.106-.531.198-.849.288-1.149l-2.147-.645c-.158.526-.29 1.042-.422 1.794h-2.451v2.083h2.184c-.058.673-.093 1.371-.103 2.077-2.413.886-3.437 2.575-3.437 4.107 0 1.809 1.427 3.399 3.684 3.194 2.802-.255 4.673-2.371 5.77-4.974 1.134.654 1.608 1.753 1.181 2.771-.396.941-1.561 1.838-3.785 1.792v2.242c2.469.038 4.898-.899 5.85-3.166.93-2.214-.132-4.635-2.525-5.793zm-2.892 1.531c-.349.774-.809 1.543-1.395 2.149-.09-.645-.151-1.352-.184-2.107.533-.07 1.072-.083 1.579-.042zm-3.788.724c.062.947.169 1.818.317 2.596-1.996.365-2.076-1.603-.317-2.596z"/>
                    </svg>    
                </router-link> -->
                <button @click="showContact = true" class="text-secondary opacity-90 w-1/5 h-10 flex aic jcc">
                    <span class="sr-only">Contact</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/>
                    </svg>                
                </button>
                <router-link to="/login" class="text-secondary opacity-90 w-1/5 h-10 flex aic jcc">
                    <span class="sr-only">Account</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z"/>
                    </svg>                
                </router-link>
            </div>
        </div>    
        <Impressum
        :showImpressum="showImpressum"
        @close="showImpressum = false"
        ></Impressum>
        <NewContact
        :showContact="showContact"
        @close="showContact = false"
        ></NewContact>
        <MobileMenu
        :mobileMenuModal="mobileMenuModal"
        @close="mobileMenuModal = false"
        ></MobileMenu>

    </div>
</template>
<script>
import MobileMenu from "../components/MobileMenu.vue";
import Impressum from "../components/Impressum.vue";
import NewContact from "../components/NewContact.vue"

export default {
    components: {Impressum, NewContact, MobileMenu},
    data() {
        return {
            mobileMenuModal: false,
            showImpressum: false,
            showContact: false,
        }
    },
}
</script>
