<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        v-if="showProbeModal"
        class="fixed inset-0 w-screen min-h-screen lg:h-screen flex flex-col lg:flex-row items-center justify-center bg-white z-99"
      >
        <button
          class="fixed right-5 top-5 cs-button p-4 outline-none focus:outline-none"
          @click="$emit('close')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 md:w-5 md:h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
          </svg>
        </button>
        <div class="w-7/12 h-screen shadow-2xl hidden lg:flex aic jcc">
          <img
            src="/img/probe-2.webp"
            alt="login"
            class="w-full h-screen object-cover mirror"
          >
        </div>
        <div class="flex flex-col items-center justify-center w-full lg:w-5/12 h-screen overflow-y-scroll sm:p-7 md:p-12 auth-back">
          <div class="pt-10 pb-20 flex flex-col w-11/12 ais jcc px-4 lg:px-0">
            <!-- <div class="flex flex-col w-full text-left">
                            <h2 class="text-xl merienda-bold text-brown">GRATIS PROBIEREN</h2>
                            <div class="flex flex-col mt-3 w-full text-sm lg:text-base">
                                Bitte füllen Sie die Felder aus, um zu uns Kontakt aufzunehmen.
                            </div>
                        </div> -->
            <div class="flex jce relative pt-16 md:pt-0 w-full max-w-4xl mx-auto">
              <div class="flex aic jcs w-full">
                <span class="w-5 h-12 bg-brown-lightest shadow-xl"></span>
                <router-link
                  to="/"
                  class="flex flex-col ais jcc ml-5"
                >
                  <small class="fs-10 text-gray-600 tracking-widest uppercase">{{$t('Bitte füllen Sie die Felder aus, um zu uns Kontakt aufzunehmen.')}}</small>
                  <h1 class="roboto-bold text-xl md:text-2xl">{{$t('GRATIS PROBIEREN')}}</h1>
                </router-link>
              </div>
            </div>
            <form
              class="flex flex-col aie jcc w-full mt-10"
              autocomplete="off"
              @submit.prevent="OnSubmit"
            >
              <input
                type="text"
                name="name"
                id="name"
                autocomplete="name"
                placeholder="Name"
                required="required"
                v-model="user.name"
                aria-label="name"
                class="block w-full px-3 py-6 bg-transparent border-b border-gray-200 new-input dmSans-bold"
              />
              <input
                type="email"
                name="mobile-or-email"
                id="mobile-or-email"
                autocomplete="email"
                v-model="user.email"
                placeholder="E-Mail"
                required="required"
                aria-label="email"
                class="block w-full px-3 py-6 bg-transparent border-b border-gray-200 new-input dmSans-bold"
              />
              <input
                type="tel"
                id="phone"
                name="telephone"
                placeholder="Telephone"
                required="required"
                v-model="user.telephone"
                aria-label="mobile"
                class="block w-full px-3 py-6 bg-transparent border-b border-gray-200 new-input dmSans-bold"
              />
              <VueGoogleAutocomplete
                id="map"
                ref="map"
                classname="block w-full px-3 py-6 bg-transparent border-b border-gray-200 new-input dmSans-bold z-harder"
                placeholder="Adresse / Postleitzahl"
                :country="['de']"
                :enable-geolocation="true"
                autocomplete="off"
                @placechanged="getAddressData"
                types="address"
              >
              </VueGoogleAutocomplete>
              <button class="btn-brown-light px-12 h-50p shadow-xl nunitoSans-bold mt-10 ml-auto">
                SENDEN
              </button>
            </form>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { VueGoogleAutocomplete },
  props: ["showProbeModal"],
  data: () => ({
    typeLocation: "(cities)",
    user: {},
    type: "address",
  }),
  computed: {
    restaurantInfo() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo;
    },
  },
  methods: {
    // checkEmpty() {
    //   if (this.$refs.map.$el.value == "") {
    //     this.user.text = "";
    //   }
    // },
    getAddressData: function (addressData) {
      if (
        !(addressData.route && addressData.street_number) ||
        !addressData.postal_code
      ) {
        this.$store.commit("setshowNotif", {
          show: true,
          msg: "you need to enter a valid address.",
          type: "error",
        });
        this.$refs.map.$el.value == "";
        return;
      }
      this.user.text =
        addressData.locality +
        ", " +
        addressData.country +
        ", " +
        addressData.postal_code +
        ", " +
        addressData.route +
        ". " +
        addressData.street_number;
    },
    OnSubmit() {
      if (!this.user.text) {
        this.$store.commit("setshowNotif", {
          show: true,
          msg: "you need to enter your location.",
          type: "error",
        });
        return;
      }
      this.$store.dispatch("restaurant/contact", this.user).then((res) => {
        this.$store.commit("setshowNotif", {
          show: true,
          msg: res.msg,
          type: res.type,
        });
        if (res.type == "success") {
          this.$emit("close");
          this.user = {};
        }
      });
    },
  },
};
</script>
