<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="fixed left-0 top-0 w-screen h-screen bg-white z-hard overflow-x-hidden p-6 flex flex-col aic jcc"
        v-if="showContact"
      >
        <button
          class="fixed right-5 top-5 cs-button p-4 outline-none focus:outline-none"
          @click="$emit('close')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 md:w-5 md:h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
          </svg>
        </button>

        <div class="flex jce relative pt-16 md:pt-0 w-full max-w-4xl mx-auto">
          <div class="flex aic jcs w-full">
            <span class="w-5 h-12 bg-brown-lightest shadow-xl"></span>
            <router-link
              to="/"
              class="flex flex-col ais jcc ml-5"
            >
              <small class="fs-10 text-gray-600 tracking-widest uppercase">{{$t('Do you have a question?')}}</small>
              <h1 class="roboto-bold text-xl md:text-2xl">{{$t('Please write us')}}</h1>
            </router-link>
          </div>
        </div>
        <div class="flex flex-col items-center justify-start w-full max-w-4xl mx-auto p-7">
          <form
            @submit.prevent="OnSubmit"
            class="my-auto w-full"
          >
            <div class="flex aic md:space-x-16">
              <div class="w-full text-left transform transition-all">
                <div>
                  <div class="flex flex-col space-y-4 w-full">
                    <div>
                      <div>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          required
                          v-model="user.name"
                          class="block w-full px-3 py-6 bg-transparent border-b border-gray-200 new-input dmSans-bold"
                          aria-label="Name"
                          autocomplete="Name"
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <input
                          type="email"
                          name="email"
                          id="email-address"
                          placeholder="Email"
                          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                          required
                          v-model="user.email"
                          class="block w-full px-3 py-6 bg-transparent border-b border-gray-200 new-input dmSans-bold"
                          aria-label="Email"
                          autocomplete="email"
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <textarea
                          name="comment"
                          id="comment"
                          rows="5"
                          required
                          v-model="user.text"
                          class="block w-full px-3 py-6 bg-transparent border-b border-gray-200 new-input dmSans-bold"
                          aria-label="Comment"
                          placeholder="Comment"
                        >
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col md:flex-row lg:flex-col xl:flex-row aic jce mt-8">
                  <button
                    type="submit"
                    class="inline-flex capitalize nunitoSans-bold justify-center max-w-conetnt relative px-16 py-3 btn-brown-light"
                  >
                    {{$t("Submit")}}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "NewContact",
  props: ["showContact"],
  data() {
    return {
      user: {},
    };
  },
  methods: {
    OnSubmit() {
      this.$store.dispatch("restaurant/contact", this.user).then((res) => {
        this.$store.commit("setshowNotif", {
          show: true,
          msg: res.msg,
          type: res.type,
        });
        if (res.type == "success") this.$emit("close");
      });
    },
  },
};
</script>
