<template>
    <div>
        <transition
            enter-active-class="animate__animated animate__fadeIn animate__fast"
            leave-active-class="animate__animated animate__fadeOut animate__fast"
        >
            <div
                class="fixed left-0 top-0 w-screen min-h-screen lg:h-screen flex flex-col lg:flex-row items-center justify-center bg-white z-harder"
                v-if="mobileMenuModal"
            >
                <button class="fixed right-5 top-5 cs-button p-4 outline-none focus:outline-none" @click="$emit('close')">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3 md:w-5 md:h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
                    </svg>                
                </button>
                <div class="flex flex-col items-center justify-start w-full lg:w-5/12 h-screen overflow-y-scroll sm:p-7 pt-16 px-2 auth-back">
                    <!-- <div class="flex aic jcs px-10 pt-8 w-1/2 absolute top-0 left-0 menu-lang">
                        <lang-switcher></lang-switcher>
                    </div> -->
                    <div class="pt-10 pb-20 flex flex-col w-11/12 ais jcs px-4 lg:px-0">
                        <ul class="list-unstyled space-y-5 text-left">
                            <li><router-link to="/" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('Home')}}</router-link></li>
                            <li><router-link to="/about" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('About us')}}</router-link></li>
                            <li><router-link to="/services" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('Services')}}</router-link></li>
                            <li><router-link to="/blogs" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('Blogs')}}</router-link></li>
                            <li><button @click="showContact = true" class="merienda-bold a-secondary fs-16 whitespace-nowrap">{{$t('Contact')}}</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
// import LangSwitcher from "../components/LangSwitcher.vue";

export default {
    props: ["mobileMenuModal"],
    // components: { LangSwitcher },
}
</script>
